import { GetStaticPropsResult } from 'next';

import { REVALIDATE } from '../../src/constants/next';
import { IPageMetadata } from '../../src/interfaces/nextjs';
import { ShopLandingPage } from '../../src/views/ShopLandingPage/ShopLandingPage';
import { AppLogic } from '../../src/components/AppLogic/AppLogic';
import { GlobalLayout } from '../../src/components/Layouts/GlobalLayout';

export async function getStaticProps(): Promise<GetStaticPropsResult<IPageMetadata>> {
  return {
    props: {
      _page: 'shop',
      _pageTitle: 'Shop',
    },
    revalidate: REVALIDATE.DAILY,
  };
}

ShopLandingPage.getLayout = function getLayout(page: any) {
  return (
    <AppLogic pageProps={page.props}>
      <GlobalLayout hideStickyBannerGetApp>{page}</GlobalLayout>
    </AppLogic>
  );
};

export default ShopLandingPage;
